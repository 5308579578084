<template>
    <section>
      <h2 class="mb-5">Elenco Clienti segnalati</h2>
      <vue-good-table :columns="columns" :rows="account_list">
        <template slot="table-row" slot-scope="props">
          <span v-if="props.column.field === 'action'" class="text-nowrap">
            
          </span>
        </template>
      </vue-good-table>
    </section>
  </template>
  
  <script>
  import { Requests } from "@/api/requests.js";
  import { BButton } from "bootstrap-vue";
  import { VueGoodTable } from "vue-good-table";
  
  const dateFormatter = new Intl.DateTimeFormat("it-IT", {
    day: '2-digit',
    month: '2-digit',
    year: 'numeric',
    hour: '2-digit',
    minute: '2-digit'
});

  export default {
    components: {
      VueGoodTable,
      BButton,
    },
  
    data: () => ({
      account_list: [],
      columns: [
        {
          label: "Agente",
          field: "agent_full_name",
        },
        {
          label: "Email agente",
          field: "agent_email",
        },
        {
          label: "Azienda Cliente",
          field: "customer_companyname",
        },
        {
          label: "Partita IVA",
          field: "customer_vat_number",
        },
        {
          label: "Data Segnalazione",
          field: "created_at",
          formatFn: (val) => dateFormatter.format(new Date(val)),
        },
      ],
    }),
  
    created() {
      this.getProxiedAccountList();
    },
  
    methods: {
      async getProxiedAccountList() {
        try {
          const response = await Requests.getProxiedAccountList();
          this.account_list = response.data;
        } catch {
          console.log(err);
        }
      },
    },
  };
  </script>
  